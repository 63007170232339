

import DxList from 'devextreme-vue/list';
import SkillService from '../services/skill-service';

// :selected-items="selectedSkills"

//import service from './data.js';

//const tasks = service.getTasks();

export default {
  name: "SkillTemplate",
  components: { DxList },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const {
      nombre,
    } = this.templateData;
    //const employeeTasks = tasks.filter((task) => task.EmployeeID === this.templateData.ID);
    return {
      id_tarea: null,
      nombre: null,      
      dataSourceSkills: null,
      selectedSkills: [],
      //selectedSkills: skillTasks.filter((skill) => skill.nombre === "true"),
      detailInfo: `${nombre}`,      
    };
  },
  created() {
    this.skillService = new SkillService();
    console.log('created skill template');
  },
  mounted() {    
    this.skillService.getHabilidades().then(data => this.dataSourceSkills=data);
    console.log('mounted skill template');
  },
};
